import firebase from 'firebase/app';
import firebaseApp from './firebase';

class Api {
  constructor() {
    this.refs = {
      content: firebaseApp.database().ref('content'),
      stories: firebaseApp.database().ref('stories'),
      about: firebaseApp.database().ref('about'),
    };

    this.timestamp = firebase.database.ServerValue.TIMESTAMP;
  }

  contentFetch() {
    return new Promise((resolve) => {
      this.refs.content.once('value', (data) => {
        let result = {};

        data.forEach(v => {
          result[v.key] = v.val();
        });

        resolve(result);
      });
    });
  }

  contentUpdate(data) {
    return new Promise((resolve) => {
      return this.refs.content.update(data, resolve);
    });
  }

  storiesFetch(limit = 1000) {
    return new Promise((resolve) => {
      this.refs.stories.limitToLast(limit).once('value', (data) => {
        let result = [];

        data.forEach(v => {
          result.push({
            ...v.val(),
            id: v.key
          })
        });

        result.sort((a, b) => b.createdAt - a.createdAt);

        resolve(result);
      });
    });
  }

  storyItemFetch(id) {
    return new Promise((resolve) => {
      this.refs.stories.child(id).once('value', (data) => {
        let result = {};

        data.forEach(v => {
          result[v.key] = v.val();
        });

        resolve(result);
      });
    });
  }

  storyCreate(data) {
    return new Promise((resolve) => {
      this.refs.stories.push({...data, createdAt: this.timestamp}, (result) => {
        resolve(result);
      });
    });
  }

  storyUpdate(id, data) {
    return new Promise((resolve) => {
      this.refs.stories.child(id).update({...data, updatedAt: this.timestamp}, (result) => {
        resolve(result);
      });
    });
  }

  storyItemDelete(id) {
    return this.refs.stories.child(id).remove();
  }

  aboutFetch() {
    return new Promise((resolve) => {
      this.refs.about.once('value', (data) => {
        resolve(data.val().text);
      });
    });
  }

  aboutUpdate(text) {
    return new Promise((resolve) => {
      this.refs.about.update({ text }, (result) => {
        resolve(result);
      });
    });
  }

}

export default new Api();