import React from 'react';
import NavbarsLink from '../../ScrollTo/NavbarScroll';

const scroll = el => el.scrollIntoView({behavior: 'smooth'});

export default function LeftMenu() {
  return (
    <nav className="nav-menu">
      <NavbarsLink scroll={scroll} className="nav-link nav-link_home" to="/#home">
        <img alt="home" className="menu-link-img" src="assets/home.png"/>
      </NavbarsLink>
      <NavbarsLink scroll={scroll} className="nav-link" to="/#about">
        <img alt="user" className="menu-link-img" src="assets/user.png"/>
      </NavbarsLink>
      <NavbarsLink scroll={scroll} className="nav-link" to="/#practice">
        <img alt="armchair" className="menu-link-img" src="assets/armchair.png"/>
      </NavbarsLink>
      <NavbarsLink scroll={scroll} className="nav-link nav-link_consultations" to="/#consultations">
        <img alt="check" className="menu-link-img" src="assets/check.png"/>
      </NavbarsLink>
    </nav>
  )
}
