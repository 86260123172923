import React, {Component} from 'react';
import Api from '../../../api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      about: '',
      formChanged: false
    };
  }

  componentDidMount() {
    Api.aboutFetch().then(about => {
      this.setState({ about });
    });
  }

  handleOnChange = (about) => {
    this.setState({ formChanged: true });
    this.setState({ about });
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    this.setState({ formChanged: false });
    Api.aboutUpdate(this.state.about);
  };

  render() {
    return (
      <div className="admin-page-about">
        <h1>About</h1>

        <form onSubmit={this.handleOnSubmit}>

          {this.state.about &&
          <ReactQuill defaultValue={this.state.about}
                      onChange={this.handleOnChange}
                      theme="snow" />
          }

          <button type="submit" className="btn btn-primary" disabled={!this.state.formChanged}>Save</button>
        </form>

      </div>
    )
  }
}