import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import Api from '../../../api';

import 'rc-datetime-picker/dist/picker.css';

export default class StoryEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      story: {
        title: '',
        description: '',
        photo_url: '',
        link: '',
        published: true,
        createdAt: ''
      },

      storyId: props.match.params.storyId || ''
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnDelete = this.handleOnDelete.bind(this);
  }

  componentDidMount() {
    if (this.state.storyId) {
      Api.storyItemFetch(this.state.storyId).then(result => {
        this.setState({'story': result});
      });
    }
  }

  handleOnChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({story: {...this.state.story, [name]: value}});
  }

  handleOnSubmit(event) {
    event.preventDefault();

    if (this.state.storyId) {
      Api.storyUpdate(this.state.storyId, this.state.story).then(() => {
        this.props.history.push('/admin/stories');
      });
    }
    else {
      Api.storyCreate(this.state.story).then(() => {
        this.props.history.push('/admin/stories');
      });
    }
  }

  handleOnDelete() {
    if (window.confirm(`Удалить запись #${this.state.storyId}?`)) {
      Api.storyItemDelete(this.state.storyId).then(() => {
        this.props.history.push('/admin/stories');
      });
    }
  }

  handleDateChange = (date) => {
    this.setState({
      story: {
        ...this.state.story,
        createdAt: date.valueOf()
      }
    });
  };

  render() {
    return (
      <div className="admin-page-settings">
        <h1>Приклади консультацій</h1>

        <form onSubmit={this.handleOnSubmit}>
          <div className="form-group">
            <label>Заголовок</label>
            <input type="text" name="title" required={true} value={this.state.story.title}
                   className="form-control" onChange={this.handleOnChange} />
          </div>
          <div className="form-group">
            <label>Описание</label>
            <textarea name="description" required={true} value={this.state.story.description}
                      className="form-control" onChange={this.handleOnChange} />
          </div>
          <div className="form-group">
            <label>URL картинки</label>
            <input type="url" name="photo_url" required={true} value={this.state.story.photo_url}
                   className="form-control" onChange={this.handleOnChange} />
            {this.state.story.photo_url &&
            <div className="mt-3">
              <img alt="" src={this.state.story.photo_url} height={100} />
            </div>}
          </div>
          <div className="form-group">
            <label>URL статьи</label>
            <input type="url" name="link" required={true} value={this.state.story.link}
                   className="form-control" onChange={this.handleOnChange} />
          </div>
          <div className="form-group">
            <label>Дата</label>
            {this.state.story.createdAt &&
            <DatetimePickerTrigger
                moment={moment(this.state.story.createdAt)}
                onChange={this.handleDateChange}
            >
              <input type="text" value={moment(this.state.story.createdAt).format('DD.MM.YYYY, HH:mm')} readOnly />
            </DatetimePickerTrigger>}
          </div>
          <div className="form-check">
            <input type="checkbox" name="published"
                   checked={!!this.state.story.published} onChange={this.handleOnChange}
                   className="form-check-input" id="publishStoryCheckbox" />
            <label className="form-check-label" htmlFor="publishStoryCheckbox">Опубликовано</label>
          </div>

          <div className="form-group mt-3">
            <button className="btn btn-primary mr-3">{this.state.storyId ? 'Сохранить' : 'Добавить'}</button>
            <Link to="/admin/stories" className="btn">Отмена</Link>
            <button type="button" className="btn btn-danger float-right" onClick={this.handleOnDelete}>Удалить</button>
          </div>
        </form>
      </div>
    )
  }
}
