import React from 'react';

export default function TextConsultations({description}) {
  if (!description) {
    return null
  }
  
  const text = description.split('.');
  return (
    <div className='consultation__title-wrapper'>
      <h3 className='consultation__title-text'>Приклади консультацій</h3>
      {
        text.map((item, index) => <p key={index} className='consultation__title-subtext'>{item + '.'}</p>)
      }
    </div>
  )
}
