import React from 'react';
import {Link} from 'react-router-dom';

export default function LeftTextAbout({ description }) {
  return (
	  <div className='wrapper'>
		<div className="about-text">
		  <div className="title">
			<h3>Олексій Карачинський</h3>
			<h5>психотерапевт</h5>
		  </div>
		  <div className="description">
			<p>
			  {description}
			</p>
		  </div>
			<Link className="link-get-more" to="/about">
				<ins>
					дізнатись більше
				</ins>
			</Link>
		</div>
	  </div>
  )
}
