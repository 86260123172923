import React from 'react';
import './styles.scss';
import LeftMenu from './LeftMenu'
import RightMenu from './RightMenu'
import Logo from './Logo'

export default function FirstPage() {
  return (
    <div className="first-body" id="home">
      <div className="first-body__nav-left">
        <LeftMenu/>
      </div>
      <div className="first-body__logo">
        <Logo/>
      </div>
      <div className="first-body__nav-right">
        <RightMenu/>
      </div>
    </div>
  )
}
