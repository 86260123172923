import React from 'react';
import {Link} from "react-router-dom";

export default function RightTextPractice({achievements, description}) {
  return (
    <div>
        <h2 className="practice-body__text-title">
            {description}
        </h2>
      <h3 className="practice-body__text-item">
        {achievements.length && achievements[0]}
      </h3>
      <h3 className="practice-body__text-item practice-body__text-item_middle">
        {achievements.length && achievements[1]}
      </h3>
      <h3 className="practice-body__text-item">
        {achievements.length && achievements[2]}
      </h3>
        <Link className="practice-body__link-get-more-mob" to="/about">
            <ins>
                дізнатись більше
            </ins>
        </Link>
    </div>
  )
}
