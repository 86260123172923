import React from "react";

export default function RightMenu() {
    return (
        <nav className="nav-menu">
            <a
                className="nav-link"
                href="https://www.facebook.com/oleksii.karachynskyi"
                target={"_blank"}
            >
                <img
                    alt="fb"
                    className="menu-link-img"
                    src="assets/facebook-logo.png"
                />
            </a>
            <a
                className="nav-link"
                href="https://t.me/karachynskyi"
                target={"_blank"}
            >
                <img
                    alt="telegram"
                    className="menu-link-img"
                    src="assets/telegram-logo.png"
                />
            </a>
            <a
                className="nav-link"
                href="https://www.youtube.com/channel/UCnsvYCy2bcVxnqbE3wVOcUA"
                target={"_blank"}
            >
                <img
                    alt="youtube"
                    className="menu-link-img"
                    src="assets/youtube-logo.png"
                />
            </a>
            <a
                className="nav-link"
                href="https://www.instagram.com/oleksii.karachynskyi/"
                target={"_blank"}
            >
                <img
                    alt="instagram"
                    className="menu-link-img menu-link-img-inst"
                    src="assets/instagram.svg"
                />
            </a>
            <a
                className="nav-link"
                target={"_blank"}
                href="mailto:oleksii.karachynskyi@gmail.com"
            >
                <img
                    alt="mail"
                    className="menu-link-img"
                    src="assets/mail.png"
                />
            </a>
        </nav>
    );
}
