import React, {Fragment} from 'react';
import LeftMenu from '../Page/FirstPage/LeftMenu'
import firebaseApp from '../firebase';
import Footer from '../Page/ConsultationsPage/Footer';
import "./about.scss"

export default class About extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      about: {
        text: ''
      },
    };
  }
  
  componentDidMount() {
    return firebaseApp.database()
      .ref('about')
      .once('value')
      .then(res => res.val())
      .then(rs => this.setState({about: rs}))
  }
  
  render() {
    return (
      <Fragment>
        <div className="about-page">
        {/*<Navbar/>*/}
        <LeftMenu/>
        <div className="container">
          <div className="row">
            <div className="lead">
              <div className="wrapper">
                <article>
                  <div className="about-page-text" dangerouslySetInnerHTML={{__html: this.state.about.text}}/>
                </article>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
        </div>
      </Fragment>
    );
  }
}
