import React from 'react';

export default function Login(props) {
  const {
    changeEmail,
    changePass,
    onLogin,
    disabled,
  } = props;
  return (
    <div className="login">
      <div className="form-group">
        <input onChange={changeEmail} type="email" className="form-control" placeholder="Enter email"/>
      </div>
      <div className="form-group">
        <input onChange={changePass} type="password" className="form-control" placeholder="Password"/>
      </div>
      <button disabled={!disabled} type="submit" onClick={onLogin} className="btn btn-outline-dark">Submit</button>
    </div>
)
}
