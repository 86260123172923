import React from 'react';
import './style.scss'
import TextConsultations from './TextConsultations';
import HistoryConsultations from './HistoryConsultations';
import Footer from "./Footer";

export default function ConsultationsPage({ description, stories }) {
  return (
    <div className="consultation" id="consultations">
        <div className='wrapper'>
          <div className="consultation__title">
            <TextConsultations description={description} />
          </div>
          <div className="consultation__stories">
            <HistoryConsultations stories={stories} />
          </div>
        </div>
      <div className="consultation__footer">
        <Footer/>
      </div>
    </div>
  )
}
