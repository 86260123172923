import React from 'react';
import './style.scss';
import InfographicPractice from './InfographicPractice'
import RightTextPractice from './RightTextPractice'

export default function PracticePage({ achievements, description }) {
  return (
    
    <div className="practice-body" id="practice">
        <div className="wrapper practice-body__wrapper">
          <div className="practice-body__img-right">
            <InfographicPractice/>
          </div>
          <div className="practice-body__text-left">
            <RightTextPractice
                achievements={achievements}
                description={description}/>
          </div>
        </div>
    </div>
  )
}
