import React from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import Page from './Page/App';
import Admin from './Admin/Admin';
import About from './About/About';
import NotFound from './NotFound/NotFound';

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Page}/>
        <Route exact path="/about" component={About}/>
        <Route path="/admin" component={Admin}/>
        <Route path="*" component={NotFound}/>
      </Switch>
    </BrowserRouter>
  )
}
