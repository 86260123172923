import React, {Component} from 'react';
import firebase from '../firebase';
import Login from './Pages/Login';
import Layout from './Layout';
import './styles.scss'


export default class Admin extends Component {
  constructor() {
    super();
    this.changeEmail = this.changeEmail.bind(this)
    this.changePass = this.changePass.bind(this)
    this.onLogin = this.onLogin.bind(this)
  }
  
  state = {
    login: false,
    email: '',
    password: '',
  };
  
  changeEmail = ({target}) => {
    this.setState({email: target.value})
  };
  
  changePass = ({target}) => {
    this.setState({password: target.value})
  };
  
  onLogin = () => {
    const {
      email,
      password
    } = this.state;
    
    firebase.auth().signInWithEmailAndPassword(email, password)
  };

  onLogout = () => {
    firebase.auth().signOut();
  };
  
  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log('Auth ok');
        this.setState({login: true});
      } else {
        console.log('Error auth');
        this.setState({login: false});
      }
    });
  }
  
  render() {
    const disabled = this.state.email && this.state.password;
    
    return this.state.login ?
      <Layout onLogout={this.onLogout} /> :
      <Login changeEmail={this.changeEmail} changePass={this.changePass} onLogin={this.onLogin} disabled={disabled}/>;
  }
}
