import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Api from '../../../api';


export default class Stories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creation: false,
      stories: [],
      content: {},
      formChanged: false
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    Api.storiesFetch().then(result => {
      this.setState({'stories': result});
    });
  }

  handleOnChange(event) {
    this.setState({content: {...this.state.content, [event.target.name]: event.target.value}});
    this.setState({formChanged: true});
  }

  handleOnSubmit(event) {
    event.preventDefault();
    this.setState({formChanged: false});
    Api.contentUpdate(this.state.content);
  }

  handleEdit(key) {
    return () => {
      this.props.history.push(`/admin/stories/edit/${key}`);
    }
  }

  render() {
    return (
      <div className="admin-page-stories">
        <h1>Приклади консультацій</h1>

        <div className="mb-3">
          <Link to="/admin/stories/edit" className="btn btn-primary">Добавить новую</Link>
        </div>

        <table className="table table-hover">
          <tbody>
          {this.state.stories.map(item => (
            <tr key={item.id} className="pointer" onClick={this.handleEdit(item.id)}>
              <td>
                <img alt="" src={item.photo_url} height={100} />
              </td>
              <td>
                <div><b>{item.title}</b></div>
                <div className="date">{item.createdAt && moment(item.createdAt).format('DD.MM.YYYY, HH:mm')}</div>
                <div>{item.description}</div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>

      </div>
    )
  }
}
