import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'bootstrap/dist/css/bootstrap.css';
import FirstPage from './FirstPage/IndexPage';
import AboutPage from './AboutPage/IndexPage';
import PracticePage from './PracticePage/IndexPage';
import ConsultationsPage from './ConsultationsPage/IndexPage';
import Api from '../api';
import ScrollTo from '../ScrollTo/ScrollTo';
import './App.scss';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: {},
      stories: []
    };

    Api.contentFetch().then(content => {
      this.setState({ content });
    });

    Api.storiesFetch().then(stories => {
      stories = stories.filter(item => item.published);
      this.setState({ stories });
    });
  }

  render() {
    return (
      <ScrollTo>
			<FirstPage/>
			<AboutPage description={this.state.content.about}/>
			<PracticePage description={this.state.content.about} achievements={[this.state.content.achievement_1, this.state.content.achievement_2, this.state.content.achievement_3]}/>
			<ConsultationsPage description={this.state.content.stories_description} stories={this.state.stories} />
      </ScrollTo>
    )
  }
}
