import React, {Component} from 'react';
import Api from '../../../api';

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: {},
      formChanged: false
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentDidMount() {
    Api.contentFetch().then(result => {
      this.setState({'content': result});
    });
  }

  handleOnChange(event) {
    this.setState({content: {...this.state.content, [event.target.name]: event.target.value}});
    this.setState({formChanged: true});
  }

  handleOnSubmit(event) {
    event.preventDefault();
    this.setState({formChanged: false});
    Api.contentUpdate(this.state.content);
  }

  render() {
    return (
      <div className="admin-page-settings">
        <h1>Settings</h1>

        <form onSubmit={this.handleOnSubmit}>

          {Object.keys(this.state.content).map((key) => {
            return (
              <div className="form-group" key={key}>
                <h4>{key}</h4>
                <textarea className="form-control" name={key} value={this.state.content[key]} onChange={this.handleOnChange} />
              </div>
            )
          })
          }

          <button type="submit" className="btn btn-primary" disabled={!this.state.formChanged}>Save</button>
        </form>

      </div>
    )
  }
}
