import React, {Component} from 'react';
import Slider from 'react-slick';
import TextTruncate from 'react-text-truncate';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class HistoryConsultations extends Component {
  render() {
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            dots: false
          }
        },
        {
          breakpoint: 1022,
          settings: {
            slidesToShow: 4,
            dots: false
          }
        },
      ]
    };

    let slider = '';

    if (this.props.stories && this.props.stories.length) {
      slider = (
        <Slider {...sliderSettings}>
          {this.props.stories.map((story, key) => {
            return (
                <a className="consultation__stories-wrapper"
                   key={key}
                   href={story.link}
                   target={"_blank"}>
                  <div className="consultation__stories-card">
                    <img className="consultation__stories-img"
                         src={story.photo_url} alt="logo"/>
                    <div className="consultation__stories-body">
                      <h5 className="consultation__stories-title">
                        <TextTruncate
                            line={2}
                            truncateText="…"
                            text={story.title}/>
                      </h5>
                      <div className="consultation__stories-text">
                        <TextTruncate
                            line={2}
                            truncateText="…"
                            text={story.description}/>
                      </div>
                    </div>
                  </div>
                </a>
            );
          })}
        </Slider>
      );
    }

    return (
      <div className="consultation__wrapper">
        {slider}
      </div>
    )
  }
}
