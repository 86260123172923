import React from 'react';
import {withRouter} from 'react-router-dom'

class ScrollTo extends React.Component {
  
  componentDidUpdate(prevProps) {
    const {
      hash
    } = this.props.location;
    
      if (hash) {
        const el = document.getElementById(hash.substring(1));
        el.scrollIntoView({
          behavior: 'smooth'
        });
    }
  }
  
  
  render() {
    return this.props.children
  }
}

export default withRouter(ScrollTo);
