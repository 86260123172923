import React from 'react';
import './style.scss'
import LeftTextAbout from './LeftTextAbout';

export default function AboutPage({ description }) {
  return (
    <div className="about-body" id="about">
		<LeftTextAbout description={description}/>
    </div>
  )
}
