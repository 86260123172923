import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyDtP4wQkBoojwPSN5AyrfDPJGWbYb571ME",
  authDomain: "psiholov-bobo.firebaseapp.com",
  databaseURL: "https://psiholov-bobo.firebaseio.com",
  projectId: "psiholov-bobo",
  storageBucket: "psiholov-bobo.appspot.com",
  messagingSenderId: "280052100827"
};
export default firebase.initializeApp(config);