import React from 'react';
import { Switch, Route, NavLink, Link } from 'react-router-dom';
import Home from './Pages/Home';
import Settings from './Pages/Settings/Settings';
import Articles from './Pages/Articles';
import About from './Pages/About/About';
import Stories from './Pages/Stories/Stories';
import StoryEdit from './Pages/Stories/StoryEdit';

export default function Layout({ onLogout }) {
  return (
    <div className="admin-layout">
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <div className="collapse navbar-collapse container">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink to='/admin/' className="nav-link" exact activeClassName="active">Home</NavLink>
            </li>
            {/*<li className="nav-item">
              <Link to='/admin/articles' className="nav-link">Articles</Link>
            </li>*/}
            <li className="nav-item">
              <NavLink to='/admin/stories' className="nav-link" activeClassName="active">Stories</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/admin/settings' className="nav-link" activeClassName="active">Settings</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/admin/about' className="nav-link" activeClassName="active">About</NavLink>
            </li>
          </ul>

          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to='/admin' className="nav-link" onClick={onLogout}>Logout</Link>
            </li>
          </ul>
        </div>
      </nav>

      <main role="main" className="container py-3">
        <Switch>
          <Route path='/admin' exact component={Home}/>
          <Route path='/admin/settings' component={Settings}/>
          <Route path='/admin/stories' exact component={Stories}/>
          <Route path='/admin/stories/edit' exact component={StoryEdit}/>
          <Route path='/admin/stories/edit/:storyId' component={StoryEdit}/>
          <Route path='/admin/articles' component={Articles}/>
          <Route path='/admin/about' component={About}/>
        </Switch>
      </main>
    </div>
  )
}
