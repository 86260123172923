import React from "react";

export default function InfographicPractice() {
    return (
        <img
            src="/assets/infographic-ua.svg"
            className="practice-body__img-fluid"
            alt="info"
        />
    );
}
