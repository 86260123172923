import React from "react";

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer__text-right">
                <p>
                    Олексій Карачинський
                    <br />
                    психотерапевт
                </p>
            </div>
            <div className="footer__logo">
                <img className="logo" src="assets/logo.svg" alt="logo-footer" />
            </div>
            <div className="footer__text-left">
                <p>
                    <a href="mailto:oleksii.karachynskyi@gmail.com">
                        oleksii.karachynskyi@gmail.com
                    </a>
                    {/*<br />*/}
                    {/*<a href="tel:+38 (000) 00 00 000">+38 (000) 00 00 000</a>*/}
                </p>
            </div>
        </div>
    );
}
